import React, {useEffect, useState} from 'react'
import './Settings.css';
import '../Core.css';
import MyDataHelps, {ParticipantInfo} from '@careevolution/mydatahelps-js';
import {Card, LoadingIndicator} from "@careevolution/mydatahelps-ui";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

const debounce = require('lodash.debounce');

export default function () {
    const requestWithdrawalSurveyName = 'Request Withdrawal';

    const [loading, setLoading] = useState<boolean>(true);
    const [participantInfo, setParticipantInfo] = useState<ParticipantInfo>();

    const loadParticipantInfo = (): Promise<void> => {
        return MyDataHelps.getParticipantInfo().then(participantInfo => {
            setParticipantInfo(participantInfo);
        });
    };

    const initialize = () => {
        setLoading(true);
        loadParticipantInfo().then(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        let debouncedInitialize = debounce(initialize, 500);

        debouncedInitialize();
        MyDataHelps.on('applicationDidBecomeVisible', debouncedInitialize);
        return () => {
            MyDataHelps.off('applicationDidBecomeVisible', debouncedInitialize);
        }
    }, []);

    if (loading) return (
        <div className="settings">
            <div className="settings-title cta-title">Settings</div>
            <LoadingIndicator/>
        </div>
    );

    const onOpenAccountSettings = () => {
        let options = {
            modal: false,
            title: 'MyDataHelps Account'
        };
        MyDataHelps.showDashboard('Account', options);
    };

    const onRequestWithdrawal = () => {
        MyDataHelps.startSurvey(requestWithdrawalSurveyName);
    };

    let withdrawalRequested = participantInfo!.customFields['WithdrawalRequested'] === 'Yes';

    return (
        <div className="settings">
            <div className="settings-title cta-title">Settings</div>
            <Card>
                <div className="cta" onClick={() => onOpenAccountSettings()}>
                    <div className="cta-body">
                        <div className="cta-title">Account</div>
                        <div className="cta-text">Update profile &amp; project settings</div>
                    </div>
                    <div className="cta-nav">
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </div>
                </div>
            </Card>
            {!withdrawalRequested &&
            <Card>
                <div className="cta" onClick={() => onRequestWithdrawal()}>
                    <div className="cta-body">
                        <div className="cta-title">Request withdrawal</div>
                        <div className="cta-text">Request that you be withdrawn from this study.</div>
                    </div>
                    <div className="cta-nav">
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </div>
                </div>
            </Card>
            }
            {withdrawalRequested &&
            <Card>
                <div className="cta">
                    <div className="cta-body">
                        <div className="cta-title warning">Withdrawal requested</div>
                        <div className="cta-text">Your withdrawal request has been submitted.</div>
                    </div>
                </div>
            </Card>
            }
        </div>
    )
}