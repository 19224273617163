import React, {useEffect, useState} from 'react'
import './Home.css';
import '../Core.css';
import MyDataHelps, {ExternalAccount, ParticipantInfo, PersistableDeviceDataPoint} from '@careevolution/mydatahelps-js';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faChevronRight, faWarning} from "@fortawesome/free-solid-svg-icons";
import {Card, ExternalAccountsPreview, SurveyTaskList} from "@careevolution/mydatahelps-ui";
import {Reward} from "../types/Reward";

const debounce = require('lodash.debounce');

export interface HealthPlanFeedback {
    providerIds: number[];
    message: string;
}

export default function () {
    const externalAccountsApplicationUrl = 'https://viewlibrary.careevolutionapps.com/connectehr?presentation=Push&excludeProviders=true';
    const diagnosisConfirmationSurveyName = 'Diagnosis Confirmation';
    const healthPlanReconnectFeedback = 'Please reconnect your health plan.';
    const tasksTitle = 'Tasks';

    const [loading, setLoading] = useState<boolean>(true);
    const [participantInfo, setParticipantInfo] = useState<ParticipantInfo>();
    const [healthPlans, setHealthPlans] = useState<ExternalAccount[]>();

    const loadParticipantInfo = (): Promise<void> => {
        return MyDataHelps.getParticipantInfo().then((participantInfo) => {
            setParticipantInfo(participantInfo);
        });
    };

    const recordConnectedHealthPlans = (healthPlans: ExternalAccount[]) => {
        let connectedHealthPlansDataPoint: PersistableDeviceDataPoint = {
            type: "ConnectedHealthPlans",
            value: JSON.stringify(healthPlans.map(hp => hp.provider.id))
        };
        MyDataHelps.persistDeviceData([connectedHealthPlansDataPoint]).then();
    };

    const loadExternalAccounts = (): Promise<void> => {
        return MyDataHelps.getExternalAccounts().then((externalAccounts) => {
            let healthPlans = externalAccounts.filter((externalAccount) => {
                return externalAccount.provider.category === 'Health Plan';
            });
            setHealthPlans(healthPlans);
            recordConnectedHealthPlans(healthPlans);
        });
    };

    const initialize = () => {
        setLoading(true);
        loadParticipantInfo().then(() => {
            loadExternalAccounts().then(() => {
                setLoading(false);
            });
        });
    };

    useEffect(() => {
        let debouncedInitialize = debounce(initialize, 500);

        debouncedInitialize();
        MyDataHelps.on('applicationDidBecomeVisible', debouncedInitialize);
        return () => {
            MyDataHelps.off('applicationDidBecomeVisible', debouncedInitialize);
        }
    }, []);

    if (loading) return null;

    const onConnectHealthPlan = () => {
        MyDataHelps.openApplication(externalAccountsApplicationUrl)
    };

    const onConfirmDiagnosis = () => {
        MyDataHelps.startSurvey(diagnosisConfirmationSurveyName);
    };

    const onViewRewards = () => {
        MyDataHelps.showTab('Rewards');
    };

    function onGetHelp() {
        MyDataHelps.openApplication(new URL('help', document.location as any).href, {modal: true});
    }

    let healthPlanConnected = healthPlans!.length > 0;
    let healthPlanApproved = participantInfo!.customFields['HealthPlanApproved'] === 'Yes';
    let healthPlanEverApproved = participantInfo!.customFields['HealthPlanEverApproved'] === 'Yes';

    let healthPlanFeedback: string | undefined = undefined;
    let healthPlanFeedbackJSON = participantInfo!.customFields['HealthPlanFeedback'];
    if (healthPlanFeedbackJSON) {
        let feedback: HealthPlanFeedback = JSON.parse(healthPlanFeedbackJSON);
        if (!healthPlans!.find((hp) => !feedback!.providerIds.includes(hp.provider.id))) {
            healthPlanFeedback = feedback.message;
        }
    }

    let diagnosisConfirmed = participantInfo!.customFields['DiagnosisConfirmed'] === 'Yes';
    let diagnosisNotConfirmable = participantInfo!.customFields['DiagnosisConfirmed'] === 'No';
    let diagnosisDocumentUploaded = participantInfo!.customFields['DiagnosisDocumentUploaded'] === 'Yes';
    let diagnosisDocumentFeedback = participantInfo!.customFields['DiagnosisDocumentFeedback'];

    let rewards: Reward[] = JSON.parse(participantInfo!.customFields['Rewards'] || '[]');

    let hasPendingGiftCard = !!rewards.find(r => !r.giftCardId);

    return (
        <div className="home">
            {hasPendingGiftCard &&
            <Card>
                <div className="cta" onClick={() => onViewRewards()}>
                    <div className="cta-body">
                        <div className="cta-title">Claim your gift card</div>
                        <div className="cta-text">You have received a gift card for completing a task.</div>
                    </div>
                    <div className="cta-nav">
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </div>
                </div>
            </Card>
            }
            {!healthPlanConnected &&
            <div>
                <Card>
                    <div className="cta" onClick={() => onConnectHealthPlan()}>
                        <div className="cta-body">
                            <div className="cta-title">Connect your health plan</div>
                            {healthPlanEverApproved ?
                                <div className="cta-text cta-feedback"><FontAwesomeIcon className="cta-warning" icon={faWarning}/> {healthPlanFeedback || healthPlanReconnectFeedback}</div>
                                : <div className="cta-text">We'll receive claims data to learn more about your health history.</div>
                            }
                        </div>
                        <div className="cta-nav">
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="cta" onClick={() => onGetHelp()}>
                        <div className="cta-body">
                            <div className="cta-title">Tips for connecting to your health plan</div>
                            <div className="cta-text">Click here for more information on how to connect to your health plan.</div>
                        </div>
                        <div className="cta-nav">
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </div>
                    </div>
                </Card>
            </div>
            }
            {healthPlanConnected && !healthPlanApproved &&
            <Card>
                <ExternalAccountsPreview excludeProviders={true} excludeDeviceManufacturers={true} applicationUrl={externalAccountsApplicationUrl}/>
                <div className="divider"/>
                <div className="cta">
                    {healthPlanFeedback ?
                        <div className="cta-text cta-feedback"><FontAwesomeIcon className="cta-warning" icon={faWarning}/> {healthPlanFeedback}</div>
                        : <div className="cta-text">Thank you for connecting a health plan. Your data will be reviewed within the next 30 minutes to confirm your eligibility.</div>
                    }
                </div>
            </Card>
            }
            {healthPlanEverApproved && !diagnosisNotConfirmable && !diagnosisConfirmed && !diagnosisDocumentUploaded &&
            <Card>
                <div className="cta">
                    <div className="cta-body">
                        <div className="cta-title">Confirm your eligibility</div>
                        <div className="cta-text">Thank you for connecting a health plan. Your data will be reviewed within the next 30 minutes to confirm your eligibility.</div>
                    </div>
                </div>
            </Card>
            }
            {healthPlanEverApproved && diagnosisNotConfirmable && !diagnosisConfirmed && !diagnosisDocumentUploaded &&
            <Card>
                <div className="cta" onClick={() => onConfirmDiagnosis()}>
                    <div className="cta-body">
                        <div className="cta-title">Confirm your eligibility</div>
                        <div className="cta-text">We need additional documentation regarding your diagnosis.</div>
                    </div>
                    <div className="cta-nav">
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </div>
                </div>
            </Card>
            }
            {healthPlanEverApproved && !diagnosisConfirmed && diagnosisDocumentUploaded &&
            <Card>
                <div className="cta" onClick={diagnosisDocumentFeedback ? () => onConfirmDiagnosis() : undefined}>
                    <div className="cta-body">
                        <div className="cta-title">Eligibility confirmation</div>
                        {diagnosisDocumentFeedback ?
                            <div className="cta-text cta-feedback"><FontAwesomeIcon className="cta-warning" icon={faWarning}/> {diagnosisDocumentFeedback}</div>
                            : <div className="cta-text">Thank you for confirming your medical information. We will be reviewing the document you uploaded and will notify you when we are ready for you to continue with the study.</div>
                        }
                    </div>
                    {diagnosisDocumentFeedback &&
                    <div className="cta-nav">
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </div>
                    }
                </div>
            </Card>
            }
            <Card>
                <SurveyTaskList title={tasksTitle} status="incomplete"/>
            </Card>
            {healthPlanConnected && healthPlanApproved &&
            <Card>
                <ExternalAccountsPreview excludeProviders={true} excludeDeviceManufacturers={true} applicationUrl={externalAccountsApplicationUrl}/>
                <div className="divider"/>
                <div className="cta">
                    <div className="cta-text"><FontAwesomeIcon className="cta-check" icon={faCheckCircle}/> Confirmed</div>
                </div>
            </Card>
            }
            {diagnosisConfirmed &&
            <Card>
                <div className="cta">
                    <div className="cta-body">
                        <div className="cta-title">Eligibility confirmation</div>
                        <div className="cta-text"><FontAwesomeIcon className="cta-check" icon={faCheckCircle}/> Confirmed</div>
                    </div>
                </div>
            </Card>
            }
        </div>
    )
}