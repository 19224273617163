import React, {useEffect, useState} from 'react'
import './Rewards.css';
import '../Core.css';
import MyDataHelps, {ParticipantInfo} from '@careevolution/mydatahelps-js';
import {Reward} from "../types/Reward";
import {Card, LoadingIndicator} from "@careevolution/mydatahelps-ui";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import {faCheckCircle, faLock, faXmarkCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const debounce = require('lodash.debounce');
const moment = require('moment');

export default function () {
    const [loading, setLoading] = useState<boolean>(true);
    const [participantInfo, setParticipantInfo] = useState<ParticipantInfo>();
    const [rewards, setRewards] = useState<Reward[]>();

    const loadParticipantInfo = (): Promise<ParticipantInfo> => {
        return MyDataHelps.getParticipantInfo().then(participantInfo => {
            setParticipantInfo(participantInfo);
            return participantInfo;
        });
    };

    const loadRewards = (participantInfo: ParticipantInfo): Promise<void> => {
        if (!participantInfo.customFields['Rewards']) {
            setRewards([]);
            return Promise.resolve();
        }

        let rewardsUrl = participantInfo.projectID === '5fb6ed05-6e35-4c1e-8775-0b0c8fcdba5b'
            ? 'https://api.mydatahelps.org/cue/giftcard'
            : 'https://api.mydatahelps.org/cue-dryrun/giftcard'

        let requestInit: RequestInit = {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + MyDataHelps.token.access_token
            })
        };

        return fetch(rewardsUrl, requestInit).then(res => res.json()).then(rewards => {
            setRewards(rewards);
        });
    };

    const initialize = () => {
        setLoading(true);
        loadParticipantInfo().then(participantInfo => {
            loadRewards(participantInfo).then(() => {
                setLoading(false);
            });
        });
    };

    useEffect(() => {
        let debouncedInitialize = debounce(initialize, 500);

        debouncedInitialize();
        MyDataHelps.on('applicationDidBecomeVisible', debouncedInitialize);
        return () => {
            MyDataHelps.off('applicationDidBecomeVisible', debouncedInitialize);
        }
    }, []);

    if (loading) return (
        <div className="rewards">
            <div className="rewards-title cta-title">Rewards</div>
            <LoadingIndicator/>
        </div>
    );

    const onRevealReward = (reward: Reward) => {
        reward.revealed = true;
        setRewards([...rewards!]);
    };

    const onHideReward = (reward: Reward) => {
        reward.revealed = false;
        setRewards([...rewards!]);
    };

    let onboardedDate = participantInfo!.customFields['OnboardedDate'];

    let today = moment().startOf('day');

    const createCheckInReward = (month: number, rewards: Reward[], addConsolationRewardWhenActive?: boolean): Reward => {
        let title = 'Month Check-In Gift Card';
        if (month === 1) {
            title = '1st ' + title;
        } else if (month === 2) {
            title = '2nd ' + title;
        } else if (month === 3) {
            title = '3rd ' + title;
        } else {
            title = month.toString() + 'th ' + title;
        }

        let amount = 10;
        let availableStart = onboardedDate ? moment(onboardedDate).add(month, 'month') : null;
        let availableEnd = onboardedDate ? moment(onboardedDate).add(month + 1, 'month').add(-1, 'day') : null;

        if (addConsolationRewardWhenActive && availableStart && availableStart <= today && availableEnd && availableEnd > today) {
            amount = 10 + ((90 - rewards.reduce((sum, reward) => sum + reward.amount, 0)) / 2);
        }

        return {
            rewardId: month.toString().padStart(2, '0'),
            title: title,
            amount: amount,
            availableStart: availableStart,
            availableEnd: availableEnd
        };
    };

    let allRewards: Reward[] = [];
    allRewards.push(rewards!.find(r => r.rewardId === 'BL') || {rewardId: 'BL', title: 'Baseline Gift Card', amount: 20});
    allRewards.push(rewards!.find(r => r.rewardId === '01') || createCheckInReward(1, rewards!));
    allRewards.push(rewards!.find(r => r.rewardId === '02') || createCheckInReward(2, rewards!));
    allRewards.push(rewards!.find(r => r.rewardId === '03') || createCheckInReward(3, rewards!));
    allRewards.push(rewards!.find(r => r.rewardId === '04') || createCheckInReward(4, rewards!));
    allRewards.push(rewards!.find(r => r.rewardId === '05') || createCheckInReward(5, rewards!));
    allRewards.push(rewards!.find(r => r.rewardId === '06') || createCheckInReward(6, rewards!));
    allRewards.push(rewards!.find(r => r.rewardId === '07') || createCheckInReward(7, rewards!));
    allRewards.push(rewards!.find(r => r.rewardId === '08') || createCheckInReward(8, rewards!, true));

    let endOfStudyReward = rewards!.find(r => r.rewardId === 'ES');
    if (endOfStudyReward) {
        allRewards.push(endOfStudyReward);
    }

    let claimsConnectionReward = rewards!.find(r => r.rewardId === 'CC');
    if (claimsConnectionReward) {
        allRewards.push(claimsConnectionReward);
    }

    return (
        <div className="rewards">
            <div className="rewards-title cta-title">Rewards</div>
            <div className="rewards-subtitle">The Amazon gift cards below can be earned by participating in this study.</div>
            <div>
                {allRewards.map((reward, i) => {
                    return <Card key={reward.rewardId} className="reward">
                        <div className="reward-content">
                            <div className="reward-title">{reward.title} - ${reward.amount}</div>
                            {reward.giftCardCode &&
                            <div>
                                {reward.revealed ?
                                    <div className="reward-gift-card-code">Code: {reward.giftCardCode} <span className="reward-hide link" onClick={() => onHideReward(reward)}>(hide)</span></div>
                                    : <div className="reward-gift-card-code">Code: {''.padStart(reward.giftCardCode!.length, '*')} <span className="reward-reveal link" onClick={() => onRevealReward(reward)}>(show)</span></div>
                                }
                            </div>
                            }
                            {!reward.giftCardCode && reward.rewardId !== 'BL' &&
                            <div className="reward-metadata">
                                {reward.availableStart && reward.availableStart > today &&
                                <div className="reward-available">Available: {reward.availableStart.format('MM/DD/YY')}</div>
                                }
                                {reward.availableEnd && reward.availableEnd < today &&
                                <div className="reward-expired">Expired: {reward.availableEnd.format('MM/DD/YY')}</div>
                                }
                            </div>
                            }
                        </div>
                        {reward.giftCardCode ?
                            <FontAwesomeIcon className="reward-check cta-check" icon={faCheckCircle}/>
                            : reward.availableEnd && reward.availableEnd < today ?
                                <FontAwesomeIcon className="reward-check cta-xmark" icon={faXmarkCircle}/>
                                : reward.availableStart && reward.availableStart > today ?
                                    <FontAwesomeIcon className="reward-check cta-lock" icon={faLock}/>
                                    : <FontAwesomeIcon className="reward-check cta-check-not-earned" icon={faCircleCheck}/>
                        }
                    </Card>
                })}
            </div>
        </div>
    )
}