import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from './views/Home';
import Rewards from './views/Rewards';
import Help from "./views/Help";
import Settings from "./views/Settings";

if (window.location.hostname === "localhost") {
    window.MyDataHelps.setParticipantAccessToken({"access_token": process.env.REACT_APP_PARTICIPANT_ACCESS_TOKEN, "expires_in": 21600, "token_type": "Bearer"}, "https://mydatahelps.org/");
}

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/home/" element={<Home/>}/>
                <Route path="/rewards/" element={<Rewards/>}/>
                <Route path="/settings/" element={<Settings/>}/>
                <Route path="/help/" element={<Help/>}/>
            </Routes>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);