import React from 'react';
import './Help.css';
import MyDataHelps from '@careevolution/mydatahelps-js';

export default function () {

    const onClose = () => {
        MyDataHelps.dismiss();
    };

    return (
        <div className="help">
            <div className="help-nav">
                <a className="help-close-button" onClick={() => onClose()}>Close</a>
            </div>
            <h3>How to share health plan data</h3>
            <div className="help-text">
                <p>Data can be shared by searching for your health plan on the next screen, logging into your health plan account, and agreeing to share data. ImmunoCARE will not access your username and password when you log in. Below is some information on how to share based on your health
                    plan.</p>
                <p><b>Medicare and/or Medicare</b>: Search for the health plan that administers your benefits (e.g. Aetna or your state’s Medicaid plan). If you do not find it, search for “CMS” (the Centers for Medicaid and Medicare Services). “Medicare / CMS” should come up. When you click on your
                    plan, you will go to a login page. After logging in, you may be asked whether you want to share all of your information or just your health information. You can select either option.</p>
                <p><b>Elevance</b> (formerly known as <b>Anthem</b>): Search for your specific plan in the dropdown, and click it to log in. Alternatively, you can search for “Anthem” and log in. This includes cases in which Elevance is managing your medicare claims.</p>
                <p>If you have trouble sharing your health plan data, please contact the study team at ImmunoCARE@Scripps.edu.</p>
                <div><b>List of Elevance (formerly Anthem) Plans</b></div>
                <ul>
                    <li>Amerigroup</li>
                    <li>Amerigroup Medicaid</li>
                    <li>Anthem</li>
                    <li>Anthem Blue Cross CA</li>
                    <li>Anthem Medicaid</li>
                    <li>Blue Cross Blue Shield Kansas Medicare Part D</li>
                    <li>Blue Cross Blue Shield Kansas City</li>
                    <li>Blue Medicare Advantage</li>
                    <li>Blue Rx New York</li>
                    <li>Clear Health Alliance</li>
                    <li>Dell Children's Health Plan</li>
                    <li>Empire Blue</li>
                    <li>Empire Blue Medicaid</li>
                    <li>Healthy Blue LA</li>
                    <li>Healthy Blue LA Medicaid</li>
                    <li>Healthy Blue MO</li>
                    <li>Healthy Blue MO Medicaid</li>
                    <li>Healthy Blue NC Medicaid</li>
                    <li>Healthy Blue NE</li>
                    <li>Healthy Blue North Carolina</li>
                    <li>Healthy Blue SC</li>
                    <li>Highmark Western New York</li>
                    <li>Simply Healthcare Medicaid</li>
                    <li>Simply Healthcare Medicare</li>
                    <li>Summit Community Care</li>
                    <li>Unicare</li>
                    <li>Unicare Massachusetts</li>
                    <li>Unicare Medicaid</li>
                    <li>Wellpoint</li>
                </ul>
            </div>
        </div>
    )
}